
import {
    mapState,
    mapActions,
    mapMutations
} from 'vuex'
export default {
    name: 'PropertyManager',
    data() {
        return {
            loginDialog: false,
            clipped: false,
            drawer: false,
            fixed: false,
            items: [{
                    icon: 'mdi-apps',
                    title: 'Welcome',
                    to: '/',
                },
                {
                    icon: 'mdi-chart-bubble',
                    title: 'Inspire',
                    to: '/inspire',
                },
            ],
            backgroundColor: 'white',
            miniVariant: false,
            right: true,
            rightDrawer: false,
            title: 'Vuetify.js',
            propertyManagerNav: [{
                    url: '/property-manager',
                    name: this.$t('Home'),
                },
                {
                    url: '/property-manager/about',
                    name: this.$t('About'),
                },
                {
                    url: '/property-manager/available',
                    name: this.$t('Property Listings'),
                },
                {
                    url: '/property-manager/services',
                    name: this.$t('Services'),
                },
                {
                    url: '/property-manager/testimonial',
                    name: this.$t('Testimonials'),
                },
                {
                    url: '/property-manager/team',
                    name: this.$t('Team'),
                },
                {
                    url: '/property-manager/why-us',
                    name: this.$t('Why Us'),
                },
                {
                    url: '/property-manager/contact',
                    name: this.$t('Contact Us'),
                },
            ],
        }
    },
    created() {
        // this.setMobileMenu(this.propertyManagerNav)
        // this.setMobileLogo(require('~/assets/img/Logo-sq.png'))
        // this.setMobileMenuHomeUrl('/home')
        // this.setMobileMenuManagementLoginBtn(true)
    },
    mounted() {
        const host = window.location.host
        const parts = host.split('.')
        console.log(parts)
        this.subdomainName = parts[0]
        if (this.subdomainName) {
            this.getSubDomainConfig()
        }
    },
    watch: {
        isLogInDialogShowOpen() {
            this.loginDialog = this.isLogInDialogShowOpen
        },
    },
    computed: {
        ...mapState('config/site', [
            'isBlur',
            'siteConfig',
            'isScheduleShowOpen',
            'isLogInDialogShowOpen',
        ]),
        ...mapState('subdomain', ['subDomainConfig']),
    },
    methods: {
        ...mapActions('config/menu', [
            'setMobileMenu',
            'setMobileLogo',
            'setMobileMenuHomeUrl',
            'setMobileMenuManagementLoginBtn',
        ]),
        ...mapActions('subdomain', ['getConfig']),
        ...mapMutations('config/site', ['setLogInDialogShow']),
        async getSubDomainConfig() {
            //   if (process.client) {
            //   this.$nuxt.$loading.start()
            // }
            this.getConfig({
                subdomainName: this.subdomainName
            }).then(res => {
                this.managerConfig = res.data
                if (this.managerConfig) {
                    this.$vuetify.theme.themes.light.primary = this.managerConfig.primaryColor
                    this.backgroundColor = this.managerConfig.backgroundColor
                    console.log(this.$vuetify)
                    if (process.client) {
                        this.$nuxt.$loading.finish()
                    }
                }
            })
        }
    },
    destroyed() {
        this.setLogInDialogShow(false)
    },
}
