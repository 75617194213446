
export default {
  props: {
    filePondId: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: 'image',
    },
    height: {
      type: String,
      default: 'image',
    },
  },
}
