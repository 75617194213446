const controller = '/account'
export default axios => ({
  login(obj) {
    return axios.post(`${controller}/login`, obj)
  },
  register(obj) {
    return axios.post(`${controller}/register`, obj)
  },
  forgetPasword(obj) {
    return axios.post(`${controller}/forget-password`, obj)
  },
  resetPassword(obj) {
    return axios.post(`${controller}/reset-password?token=${obj.token}`, {
      newPassword: obj.newPassword,
      confirmNewPassword: obj.confirmNewPassword,
    })
  },
  verifyEmail(obj) {
    return axios.post(`${controller}/verify-email?token=${obj.token}`, obj)
  },
  getUser(obj) {
    return axios.get(`${controller}/userinfo`, obj)
  },
  chosePakcage(obj) {
    return axios.post(`${controller}/choose-package`, obj)
  },
  checkThairathUser(obj) {
    console.log("obj",obj)
    return axios.get(`${controller}/thairath-user?email=${obj.email}`)
  },
  changePassword(obj) {
    console.log("obj",obj)
    return axios.put(`${controller}/password`,obj)
  },
})
