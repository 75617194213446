
export default {
  props: {
    showGridButton: {
      type: Boolean,
      default: true,
    },
    showItemPerPage: {
      type: Boolean,
      default: true,
    },
    pageCount: {
      type: Number,
      default: 10,
    },
    gridOrListActive: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    itemsPerPageFromProp: {
      type: Number,
      default: 10,
    },
    itemsPerPageItems:{
      type:Array,
      default:[5, 10, 15, 20, 25, 30]
    }
  },
  data() {
    return {
      gridOrList: this.gridOrListActive,
      page: 1,
      itemsPerPage: 10,
      items: [5, 10, 15, 20, 25, 30],
    }
  },
  watch: {
    gridOrList() {
      this.$emit('gridOrList', this.gridOrList)
    },
    itemsPerPage() {
      this.$emit('itemsPerPage', this.itemsPerPage)
    },
    page() {
      this.$emit('page', this.page)
    },
    // currentPage(){
    //   this.page=this.currentPage
    // },
  },
  created(){
    console.log(this.currentPage)
    this.page=this.currentPage
    console.log(this.page)
    this.itemsPerPage=this.itemsPerPageFromProp
  }
}
