const controller = '/personal-website'
export default axios => ({
  designYourWebsiteHomePage(obj) {
    return axios.put(`${controller}/customize-design/homepage`, obj)
  },
  getDesignYourWebsiteHomePage(obj) {
    return axios.get(`${controller}/customize-design/homepage`)
  },
  designYourWebsiteAboutPage(obj) {
    return axios.put(`${controller}/customize-design/about-page`, obj)
  },
  getDesignYourWebsiteAboutPage(obj) {
    return axios.get(`${controller}/customize-design/about-page`)
  },
  designYourWebsiteFeaturePage(obj) {
    return axios.put(`${controller}/customize-design/featured-page`, obj)
  },
  getDesignYourWebsiteFeaturePage(obj) {
    return axios.get(`${controller}/customize-design/featured-page`)
  },
  designYourWebsiteContactUsPage(obj) {
    return axios.put(`${controller}/customize-design/contact-us-page`, obj)
  },
  getDesignYourWebsiteContactUsPage(obj) {
    return axios.get(`${controller}/customize-design/contact-us-page`)
  },
  designYourWebsiteServicePage(obj) {
    return axios.put(`${controller}/customize-design/service-page`, obj)
  },
  getDesignYourWebsiteServicePage(obj) {
    return axios.get(`${controller}/customize-design/service-page`)
  },
  designYourWebsiteTeamPage(obj) {
    return axios.put(`${controller}/customize-design/team-page`, obj)
  },
  getDesignYourWebsiteTeamPage(obj) {
    return axios.get(`${controller}/customize-design/team-page`)
  },
  
  designYourWebsiteTestimonialPage(obj) {
    return axios.put(`${controller}/customize-design/testimonial-page`, obj)
  },
  getDesignYourWebsiteTestimonialPage(obj) {
    return axios.get(`${controller}/customize-design/testimonial-page`)
  },
 
  designYourWebsiteWhyUsPage(obj) {
    return axios.put(`${controller}/customize-design/why-us-page`, obj)
  },
  getDesignYourWebsiteWhyUsPage(obj) {
    return axios.get(`${controller}/customize-design/why-us-page`)
  },
  getExtraPage(obj) {
    return axios.get(`${controller}/extra-pages`)
  },
  deleteDesignYourWebsiteWhyUsPage(obj) {
    return axios.delete(`${controller}/customize-design/why-us-page`)
  },
  deleteDesignYourWebsiteServicePage(obj) {
    return axios.delete(`${controller}/customize-design/service-page`)
  },
  deleteDesignYourWebsiteTeamPage(obj) {
    return axios.delete(`${controller}/customize-design/team-page`)
  },
  deleteDesignYourWebsiteTestimonialPage(obj) {
    return axios.delete(`${controller}/customize-design/testimonial-page`)
  },
  enableTabs(obj){
    return axios.put(`${controller}/customize-design/enable-tab`,obj)
  },
  changeSearchOption(obj){
    return axios.put(`${controller}/search-options`,obj)
  },
  getSearchOption(obj){
    return axios.get(`${controller}/search-options`)
  }
})
