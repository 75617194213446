const actions = {
  async designYourWebsiteHomePage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.designYourWebsiteHomePage(
        params
      )

    return res.data.data
  },
  async getDesignYourWebsiteHomePage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.getDesignYourWebsiteHomePage(
        params
      )

    return res.data.data
  },
  async designYourWebsiteAboutPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.designYourWebsiteAboutPage(
        params
      )

    return res.data.data
  },
  async getDesignYourWebsiteAboutPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.getDesignYourWebsiteAboutPage(
        params
      )

    return res.data.data
  },
  async designYourWebsiteFeaturePage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.designYourWebsiteFeaturePage(
        params
      )

    return res.data.data
  },
  async getDesignYourWebsiteFeaturePage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.getDesignYourWebsiteFeaturePage(
        params
      )

    return res.data.data
  },
  async designYourWebsiteContactUsPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.designYourWebsiteContactUsPage(
        params
      )

    return res.data.data
  },
  async getDesignYourWebsiteContactUsPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.getDesignYourWebsiteContactUsPage(
        params
      )

    return res.data.data
  },
  async designYourWebsiteServicePage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.designYourWebsiteServicePage(
        params
      )

    return res.data.data
  },
  async getDesignYourWebsiteServicePage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.getDesignYourWebsiteServicePage(
        params
      )

    return res.data.data
  },
  async designYourWebsiteTeamPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.designYourWebsiteTeamPage(
        params
      )

    return res.data.data
  },
  async getDesignYourWebsiteTeamPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.getDesignYourWebsiteTeamPage(
        params
      )

    return res.data.data
  },
  async deleteDesignYourWebsiteTeamPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.deleteDesignYourWebsiteTeamPage(
        params
      )

    return res.data.data
  },
  async getExtraPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.getExtraPage(
        params
      )

    return res.data.data
  },
  async designYourWebsiteTestimonialPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.designYourWebsiteTestimonialPage(
        params
      )

    return res.data.data
  },
  async getDesignYourWebsiteTestimonialPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.getDesignYourWebsiteTestimonialPage(
        params
      )

    return res.data.data
  },
  async deleteDesignYourWebsiteTestimonialPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.deleteDesignYourWebsiteTestimonialPage(
        params
      )

    return res.data.data
  },
  async designYourWebsiteWhyUsPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.designYourWebsiteWhyUsPage(
        params
      )

    return res.data.data
  },
  async getDesignYourWebsiteWhyUsPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.getDesignYourWebsiteWhyUsPage(
        params
      )

    return res.data.data
  },
  async deleteDesignYourWebsiteWhyUsPage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.deleteDesignYourWebsiteWhyUsPage(
        params
      )

    return res.data.data
  },
  async deleteDesignYourWebsiteServicePage({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.deleteDesignYourWebsiteServicePage(
        params
      )

    return res.data.data
  },
  async enableTabs({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.enableTabs(
        params
      )

    return res.data.data
  },
  async changeSearchOption({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.changeSearchOption(
        params
      )

    return res.data.data
  },
  async getSearchOption({ commit }, params) {
    const res =
      await this.$repositories.customizePersonalWebsite.getSearchOption(
        params
      )

    return res.data.data
  },
}
export default actions
